import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from './TtResponseSummary.Styles';
import TransportationTranslationResponse from '../../../../data-models/TransportationTranslationResponse.DataModel';
import Moment from 'moment';
import ServiceTypeEntity from '../../../../entities/ServiceType.Entity';

interface ITtResponseSummaryProps {
    transportationTranslationResponses: Array<TransportationTranslationResponse>;
    serviceTypes: Array<ServiceTypeEntity>;
}

export const TtResponseSummary: FunctionComponent<ITtResponseSummaryProps> = (props: ITtResponseSummaryProps) => {
    const classes = styles();

    return (
        <>
            {props.transportationTranslationResponses?.map((res, index) => {
                return (
                    <Accordion defaultExpanded={true} className={classes.paper} square>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="cliam-accordion"
                            className={classes.paperHeader}
                        >
                            <Typography variant="subtitle2" className={classes.paperTitle}>
                                {res?.serviceTypeName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>
                                        Appointment Schedule Status
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>
                                        {res?.scheduleStatus}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>
                                        {'Appointment Date & Time'}
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>
                                        {res?.appointmentDate
                                            ? Moment(res?.appointmentDate).format('MM/DD/YYYY') +
                                              ' - ' +
                                              Moment(res?.appointmentDate).format('HH:mm') +
                                              ' CT'
                                            : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className={classes.dataLabel}>
                                        Review Notes Summary
                                    </Typography>
                                    {res?.reviewNotes?.map((note, index) => {
                                        return (
                                            <Typography variant="subtitle2" className={classes.blackFont}>
                                                {note.reviewNotes}
                                            </Typography>
                                        );
                                    })}
                                </Grid>
                                {res?.scheduleStatus == 'Cancelled' && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className={classes.dataLabel}>
                                            Reason for Cancellation
                                        </Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>
                                            {res?.reasonForCancellation}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};
