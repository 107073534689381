import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import styles from './DmeHeaderSummary.Styles';
import Moment from 'moment';
import ServiceTypeRequestDmeEntity from '../../../../../../entities/dme/ServiceTypeRequestDme.Entity';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../../../../generic-components/material-table/MaterialTable.Component';
import ProductInformationEntity from '../../../../../../entities/dme/ProductInformation.Entity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface IDmeHeaderSummary {
    serviceTypeRequestDme: ServiceTypeRequestDmeEntity;
}

export const DmeHeaderSummary: FunctionComponent<IDmeHeaderSummary> = ({ serviceTypeRequestDme }) => {
    const classes = styles();

    const productTableProps: MaterialTableProps<ProductInformationEntity> = {
        columns: [
            {
                title: 'Product Category',
                field: 'productCategoryName',
            },
            {
                title: 'Product',
                field: 'productName',
            },
            {
                title: 'Quantity',
                field: 'quantity',
            },
            {
                title: 'Rental/Purchase',
                field: 'rentalPurchase',
            },
        ],
        data: serviceTypeRequestDme?.products ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
    };

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {
                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                        Transportation Service
                    </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MaterialTable tableProps={productTableProps} />
                    </Grid>
                    {/*row 3*/}

                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Rush Request"
                            value={serviceTypeRequestDme?.rushRequest ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Prescription"
                            value={serviceTypeRequestDme?.prescription ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/*Empty space */}
                    </Grid>
                    <Grid item container spacing={0} xs={4}>
                        <Grid item xs={6}>
                            <Typography variant="body2">Delivery Address</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" className={classes.boldBlackFont}>
                                {' '}
                                {serviceTypeRequestDme?.deliveryAddress}{' '}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        {/*Empty spcae */}
                    </Grid>
                    {/*row 4*/}
                    <Grid item xs={12}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Address"
                            value={serviceTypeRequestDme?.address}
                        />
                    </Grid>
                    {/*row 5*/}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Contact Name"
                            value={serviceTypeRequestDme?.contactName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Contact Phone Number"
                            value={serviceTypeRequestDme?.contactPhoneNumber}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Request Delivery Date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={Moment(serviceTypeRequestDme?.requestDeliveryDate)?.format('MM/DD/YYYY')}
                        />
                    </Grid>

                    {/*row 6*/}

                    <Grid item xs={12}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Additional Comments"
                            value={serviceTypeRequestDme?.additionalComments}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
