import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { DmeHeaderSummary } from './dme-header-summary/DmeHeaderSummary.Component';
import { MsReferringPhysician } from './ms-referring-physician/MsReferringPhysician.Component';
import DmeServiceDataModel from '../../../../../data-models/dme/DmeService.DataModel';
import StateEntity from '../../../../../entities/State.Entity';

type DmeReferralRequestProps = {
    dmeService: DmeServiceDataModel;
    states: StateEntity[];
};

export const DmeReferralRequest: FunctionComponent<DmeReferralRequestProps> = ({ dmeService, states }) => {
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DmeHeaderSummary serviceTypeRequestDme={dmeService?.serviceTypeRequestDme} />
                </Grid>
                <Grid item xs={12}>
                    <MsReferringPhysician medicalService={dmeService} states={states} />
                </Grid>
            </Grid>
        </form>
    );
};
