import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    root: {        
        top: '251',
        left: '897px',
        width: '398px',
        height: '40px',
        /* UI Properties */
        background: '#1599BC1A 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: '1',
        display:'flex',
        paddingTop: '9px',
        paddingLeft:'20px',
        
            },
    label: {
        textAlign: 'center',
        verticalAlign: 'middle',      
        font: 'normal normal normal 14px/19px Roboto',
        letterSpacing: '0px',
        color: '#000000',
        opacity: '1',        
        width:'300px',
        marginTop:'3px'
    },
    icon:{        
        /* Layout Properties */
        top: '259px',
        left: '913px',
        width: '24px',
        height: '24px',
        transform: 'matrix(-1, 0, 0, -1, 0, 0)',
        backgroundColor:'green',
        /* UI Properties */
    },
    iconBG:{
        color:'#007e9f'
    },
    verticalLine:{
        width: '0px',
        height: '24px',
        /* UI Properties */
        borderRight: '0.1px solid #107A98',
        opacity: '1',
        marginLeft:'20px'
    }
});